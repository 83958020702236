import React from 'react'
import './styles.scss'
import { StaticQuery, graphql } from 'gatsby'
import { Row, Col } from 'react-bootstrap'
import Glitch from 'components/glitch'
import Typewriter from 'typewriter-effect'
import ThemeContext from '../../context'
class Hero extends React.Component {

  static contextType = ThemeContext

  render() {
    return (
      <section
        id={`${this.props.id}`}
        className="hero"
        style={{ height: this.context.height }}
      >
        <Row>
          <Col md={6} className="content">
            <div className="content-text">
              {/* <div className="line-text">
                <h4></h4>
              </div> */}
              <Glitch text="Guneet Sidhu" />
              <Typewriter
                options={{
                  strings: [
                    'FullStack Developer',
                    'Mobile App Expert',
                    'Cloud Engineer'
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
              {/* <button className="hover-button">
                  <span>Download CV</span>
              </button> */}
              <div className="separator" />
              {/* <div className="content-text">
                <p>a versatile Software Engineer with a passion for excellence.</p>      
              </div> */}
              
            </div>
            {/* {this.icons()} */}
          </Col>
          <Col md={6} className="img">
            <img
              src={this.props.mainImg.childImageSharp.fluid.src}
              alt="person"
            />
          </Col>
        </Row>
      </section>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        icons: allFile(
          filter: {
            extension: { regex: "/(png)/" }
            relativeDirectory: { eq: "icons" }
          }
        ) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 100) {
                  src
                }
              }
            }
          }
        }
        Img: file(relativePath: { eq: "person.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              src
            }
          }
        }
      }
    `}
    render={({ icons, Img }) => <Hero icons={icons} mainImg={Img} {...props} />}
  />
)
