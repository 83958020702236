import React from 'react'
import Particles from 'react-particles-js';
import Progress from 'components/progress'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import ThemeContext from '../../context'
import './styles.scss'

class Hero extends React.Component {

    static contextType = ThemeContext

    render() {
        return (
            <section id={`${this.props.id}`} className="about" style={{height: this.context.height}}>
                {this.particles()}
                <Row>
                    <Col md={6} className="content">
                        <div className="content-text">
                            <div className="line-text">
                                <h4>About Me</h4>
                            </div>
                            <h3>Software Engineer with a passion for excellence</h3>
                            {/* <div className="separator" /> */}
                            <p>Software Architect with passion for designing secure, performant, resilient and cost-optimized solutions</p>
                            <p>10 years professional experience in all phases of enterprise software design, development and testing</p>
                            <p>Proficient in requirements gathering, technical design documentation, training and customer support</p>

                            {/* <div className="social social_icons">
                                <FontAwesomeIcon icon={faGithub} className="social_icon" onClick={() => window.open('https://www.github.com')}/>
                                <FontAwesomeIcon icon={faTwitter} className="social_icon" onClick={() => window.open('https://www.twitter.com')} />
                                <FontAwesomeIcon icon={faYoutube} className="social_icon" onClick={() => window.open('https://www.youtube.com')} />
                                <FontAwesomeIcon icon={faLinkedin} className="social_icon" onClick={() => window.open('https://www.linkedin.com')} />
                            </div> */}

                        </div>
                    </Col>
                    <Col md={6} className="skills">
                            <div className="line-text">
                                <h4>My Skills</h4>
                            </div>
                            <div className="skills-container">
                                <Progress name="Development" details="Java, JS ES6, C#" value={90} delay={1100} />
                                <Progress name="Full Stack" details="React, Angular, Node" value={80} delay={1100} />
                                <Progress name="Mobile" details="React Native, Android, Swift" value={80} delay={1100} />
                                <Progress name="Web" details="Design, HTML, CSS" value={75} delay={1100} />
                                <Progress name="Database" details="SQL, NoSQL, GraphQL" value={85} delay={1100} />
                                <Progress name="Cloud" details="Setup, Security, Serverless" value={80} delay={1100} />
                            </div>
                    </Col>
                </Row>
            </section>
        )
    }

    particles() {
        return (
            <Particles
                className="particles"
                params={{
                    "particles": {
                        "number": {
                            "value": 50,
                            "density": {
                                "enable": false,
                                "value_area": 5000
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "opacity": .5
                        },
                        "size": {
                            "value": 1
                        },
                    },
                    "retina_detect": true
            }}/>
        )
    }

}

export default Hero