import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import BaffleText from 'components/baffle-text'
import AnimationContainer from 'components/animation-container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReact, faAngular, faAws } from '@fortawesome/free-brands-svg-icons'
import {
  faPencilRuler,
  faServer,
  faRobot,
  faSmileBeam,
  faPizzaSlice,
  faQuoteRight,
  faCode,
  faExternalLinkAlt
} from '@fortawesome/free-solid-svg-icons'
import Counter from 'components/counter'
import ThemeContext from '../../context'
import './styles.scss'

class Services extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
    }
    this.show = this.show.bind(this)
  }

  static contextType = ThemeContext

  show() {
    this.setState({ show: true })
  }

  render() {
    return (
      <section
        id={`${this.props.id}`}
        className="services"
        style={{ height: this.context.height }}
      >
        <Row
          className="top"
          // style={{
          //   maxHeight:
          //     this.context.height !== 'auto'
          //       ? this.context.height * 0.8
          //       : 'inherit',
          // }}
        >
          <div className="content">
            <Col md={12}>
              <div className="line-text">
                <h4>My Work</h4>
              </div>
              <div className="heading">
                <BaffleText
                  text="Experience"
                  revealDuration={500}
                  revealDelay={500}
                  parentMethod={this.show}
                  callMethodTime={1100}
                />
              </div>
              <div className="services_container"
                style={{
                  minHeight:
                    this.context.height !== 'auto'
                      ? this.context.height * 0.8
                      : 'inherit',
                }}
              >
                <Container>{this.services()}</Container>
              </div>
            </Col>
          </div>
        </Row>
        {/* <Row className="bottom">{this.counters()}</Row> */}
      </section>
    )
  }

  services() {
    if (this.state.show || this.context.height === 'auto') {
      return (

        <div>

          <Row>

            <Col md={12}>

              <Col md={4} className="service">
                <AnimationContainer delay={200} animation="fadeInLeft fast">
                  {/* <div className="icon">
                    <FontAwesomeIcon icon={faReact} />
                  </div> */}
                  <h4>FullStack Engineer</h4>
                  <p className="loc">Absolute Results, Canada</p>
                  <div className="points">
                    <p>Worked on company's iPad app used by Sales for training events at dealerships.</p>
                    <p>Created a Sales training portal and worked on DriveAI web and mobile.</p>
                    <p>Tech stack - React, React Native, AWS Serverless.</p>
                  </div>
                  <div className="last">
                    <div className="date">Nov 20 - Nov 21</div> 
                  </div>
                </AnimationContainer>
              </Col>

              <Col md={4} className="service">
                <AnimationContainer delay={200} animation="fadeInLeft fast">
                  {/* <div className="icon">
                    <FontAwesomeIcon icon={faReact} />
                  </div> */}
                  <h4>Freelance Developer</h4>
                  <p className="loc">California</p>
                  <div className="points">
                    <p>Worked on freelancing projects of varying complexities.</p>
                    <p>Created MultiChess, a serverless RN app for playing realtime multiplayer Chess.</p>
                    <p>Tech stack - React Native for Android and iOS on AWS Serverless.</p>
                  </div>
                  <div className="last">
                    <div className="date">Dec 19 - May 20</div> 
                    <div>
                      <FontAwesomeIcon icon={faExternalLinkAlt} className="icon" 
                        onClick={() => window.open('https://guneetsidhu.s3-us-west-2.amazonaws.com/TheChessApp+Overview.pdf')} />
                    </div>
                  </div>
                </AnimationContainer>
              </Col>

              <Col md={4} className="service">
                <AnimationContainer delay={400} animation="fadeInDown fast">
                  {/* <div className="icon">
                    <FontAwesomeIcon icon={faAngular} />
                  </div> */}
                  <h4>Mobile Developer</h4>
                  <p className="loc">Infor, Canada</p>
                  <div className="points">
                    <p>Managed mobile and full-stack codebases for Mobile Insights.</p>
                    <p>Architected backend improvements for reduced latency and better performance.</p>
                    <p>Worked with DevOps to migrate app servers to AWS.</p>
                  </div>
                  <div className="last">
                    <div className="date">Sep 18 - Sep 19</div>
                  </div>
                </AnimationContainer>
              </Col>

            </Col>

          </Row>

          <Row>
            <Col md={12}>

              <Col md={4} className="service">
                <AnimationContainer delay={600} animation="fadeInRight fast">
                  {/* <div className="icon">
                    <FontAwesomeIcon icon={faAws} />
                  </div> */}
                  <h4>FullStack Developer</h4>
                  <p className="loc">Vivonet, Canada</p>
                  <div className="points">
                    <p>Worked on company’s restaurant manager portal called Enterprise Manager.</p>
                    <p>Technology stack - AngularJS, Grails, Java and Oracle.</p>
                    <p>Improved application performance, assisted with migration to Angular 5, mentored junior devs.</p>
                  </div>
                  <div className="last">
                    <div className="date">Apr 18 - Sep 18</div>
                  </div>
                </AnimationContainer>
              </Col>

              <Col md={4} className="service">
                <AnimationContainer delay={800} animation="fadeInLeft fast">
                  {/* <div className="icon">
                    <FontAwesomeIcon icon={faPencilRuler} className="solid" />
                  </div> */}
                  <h4>Founder</h4>
                  <p className="loc">Hospitality Startup, India</p>
                  <div className="points">
                    <p>Developed a cloud based SPA for end-to-end Restaurant Management.</p>
                    <p>Technology stack - AngularJS, Node, Express and MongoDB on AWS.</p>
                    <p>Wore many hats to develop, manage, setup and support the software.</p>
                  </div>
                  <div className="last">
                    <div className="date">Feb 17 - Jan 18</div> 
                    <div>
                      <FontAwesomeIcon icon={faExternalLinkAlt} className="icon" 
                        onClick={() => window.open('https://guneetsidhu.s3-us-west-2.amazonaws.com/RMS+Application+Overview.pdf')} />
                    </div>
                  </div>
                </AnimationContainer>
              </Col>

              <Col md={4} className="service">
                <AnimationContainer delay={1000} animation="fadeInUp fast">
                  {/* <div className="icon">
                    <FontAwesomeIcon icon={faServer} className="solid" />
                  </div> */}
                  <h4>Software Developer</h4>
                  <p className="loc">ZE PowerGroup, Canada</p>
                  <div className="points">
                    <p>FullStack developer with Ext JS, JSP, Struts, Java, MSSQL and Oracle.</p>
                    <p>Built a mobile app with Sencha Touch and Phonegap.</p>
                    <p>API developer with SOAP and REST and BI (Spotfire, Tableau, Qlik) integration.</p>
                  </div>
                  <div className="last">
                    <div className="date">Aug 12 - Dec 16</div>
                  </div>
                </AnimationContainer>
              </Col>

            </Col>

          </Row>

        </div>
      )
    }
  }
}

export default Services
